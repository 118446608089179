var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ftx-dialog',{attrs:{"dialog":_vm.dialog,"position":"standard","maximized":_vm.isMobile ? true : false,"persistent":true,"onHide":_vm.onReset,"beforeShow":_vm.beforeShow},scopedSlots:_vm._u([{key:"DialogContent",fn:function(){return [_c('q-card',{staticClass:"simpleDialog q-pa-none"},[_c('q-card-section',{class:_vm.isMobile ? 'q-px-none q-pt-lg q-pb-md' : 'q-pa-xl'},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"absolute-top-right q-mt-md q-mr-md",attrs:{"icon":"close","flat":"","round":"","dense":""}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row q-col-gutter-md justify-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"ageField"},[_c('div',{staticClass:"box"}),_c('div',{staticClass:"box ageBox"},[_vm._v("21"),_c('span',[_vm._v("+")])]),_c('div',{staticClass:"box"})])]),_c('q-form',{ref:"personalInfoForm",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"text-body1"},[_vm._v("\n                  Please verify below details as per your driver's license and\n                  continue with Age Verification process\n                ")]),_c('q-scroll-area',{staticClass:"ageVerificationHeight",staticStyle:{"max-width":"100%"},attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[_c('div',{staticClass:"row q-col-gutter-md q-mt-md"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{attrs:{"outlined":"","label":"First Name*","rules":[
                            (val) => _vm.validateRequired(val),
                            _vm.validatePersonName(
                              _vm.personalInfo.firstName,
                              'Please enter a valid name'
                            ),
                          ],"lazy-rules":_vm.lazyValidationRules,"maxlength":"40","aria-label":"ftxcommerce"},model:{value:(_vm.personalInfo.firstName),callback:function ($$v) {_vm.$set(_vm.personalInfo, "firstName", $$v)},expression:"personalInfo.firstName"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{attrs:{"outlined":"","label":"Last Name*","rules":[
                            (val) => _vm.validateRequired(val),
                            _vm.validatePersonName(
                              _vm.personalInfo.lastName,
                              'Please enter a valid name'
                            ),
                          ],"lazy-rules":_vm.lazyValidationRules,"maxlength":"40","aria-label":"ftxcommerce"},model:{value:(_vm.personalInfo.lastName),callback:function ($$v) {_vm.$set(_vm.personalInfo, "lastName", $$v)},expression:"personalInfo.lastName"}})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{attrs:{"outlined":"","mask":"##/##/####","label":"Date of Birth*","placeholder":"MM/DD/YYYY","rules":[
                            (val) => _vm.validateRequired(val),
                            _vm.validateFullDOB,
                          ],"lazy-rules":_vm.lazyValidationRules,"aria-label":"ftxcommerce"},on:{"input":_vm.onChangeDOB},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"event"}},[_c('q-popup-proxy',{ref:"qDateProxy",attrs:{"transition-show":"scale","transition-hide":"scale"}},[_c('q-date',{attrs:{"mask":"MM/DD/YYYY","options":_vm.isDateAllowed},model:{value:(_vm.configureDOB),callback:function ($$v) {_vm.configureDOB=$$v},expression:"configureDOB"}},[_c('div',{staticClass:"row items-center justify-end q-gutter-sm"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"Cancel","color":"primary","flat":""},on:{"click":_vm.cancelDateSelection}}),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"OK","color":"primary","flat":""},on:{"click":_vm.selectDate}})],1)])],1)],1)]},proxy:true}]),model:{value:(_vm.personalInfo.dob),callback:function ($$v) {_vm.$set(_vm.personalInfo, "dob", $$v)},expression:"personalInfo.dob"}})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-field pb-0"},[_c('GoogleAutoComplete',{attrs:{"outlined":true,"label":"Address Line 1*","addressFieldChange":_vm.addressFieldChange,"modelField":"addressLine1","value":_vm.personalInfo.addressLine1,"placechanged":_vm.getAddressData,"rules":[(val) => _vm.validateRequired(val)],"lazy-rules":_vm.lazyValidationRules}})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{attrs:{"outlined":"","label":"Address Line 2"},model:{value:(_vm.personalInfo.addressLine2),callback:function ($$v) {_vm.$set(_vm.personalInfo, "addressLine2", $$v)},expression:"personalInfo.addressLine2"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{attrs:{"outlined":"","label":"City*","rules":[
                            (val) => _vm.validateRequired(val),
                            _vm.validatePersonName(
                              _vm.personalInfo.city,
                              'Please enter valid city'
                            ),
                          ],"lazy-rules":_vm.lazyValidationRules,"aria-label":"ftxcommerce"},model:{value:(_vm.personalInfo.city),callback:function ($$v) {_vm.$set(_vm.personalInfo, "city", $$v)},expression:"personalInfo.city"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{attrs:{"outlined":"","label":"State*","rules":[
                            (val) => _vm.validateRequired(val),
                            _vm.validatePersonName(
                              _vm.personalInfo.state,
                              'Please enter valid state'
                            ),
                          ],"lazy-rules":_vm.lazyValidationRules,"aria-label":"ftxcommerce"},model:{value:(_vm.personalInfo.state),callback:function ($$v) {_vm.$set(_vm.personalInfo, "state", $$v)},expression:"personalInfo.state"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"outlined":"","label":"Zip Code*","rules":[_vm.validateZip],"lazy-rules":_vm.lazyValidationRules,"aria-label":"ftxcommerce"},model:{value:(_vm.personalInfo.zip),callback:function ($$v) {_vm.$set(_vm.personalInfo, "zip", $$v)},expression:"personalInfo.zip"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"outlined":"","label":"Phone Number*","rules":[
                            (val) => _vm.validateRequired(val),
                            _vm.validatePhone(),
                          ],"lazy-rules":_vm.lazyValidationRules,"aria-label":"ftxcommerce","disable":_vm.isFieldDisabled('phone')},model:{value:(_vm.personalInfo.phone),callback:function ($$v) {_vm.$set(_vm.personalInfo, "phone", $$v)},expression:"personalInfo.phone"}})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-field pb-0"},[_c('q-input',{attrs:{"outlined":"","label":"Email Address","rules":[_vm.validateEmail],"lazy-rules":_vm.lazyValidationRules,"aria-label":"ftxcommerce","disable":_vm.isFieldDisabled('email')},model:{value:(_vm.personalInfo.email),callback:function ($$v) {_vm.$set(_vm.personalInfo, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"personalInfo.email"}})],1)])])]),_c('div',{staticClass:"q-px-md"},[_c('div',{staticClass:"row q-col-gutter-md q-mt-xs"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}],staticClass:"col-6"},[_c('div',{staticClass:"form-action pb-0"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"q-btn-lg full-width",attrs:{"unelevated":"","no-caps":"","color":"tertiary","label":"Update"},on:{"click":_vm.navigateToProfilePage}})],1)]),_c('div',{class:_vm.isLoggedIn ? 'col-6' : 'col-12'},[_c('div',{staticClass:"form-action pb-0"},[_c('q-btn',{staticClass:"q-btn-lg full-width",attrs:{"unelevated":"","no-caps":"","color":"secondary","label":"Continue","type":"submit","loading":_vm.loading}})],1)])])])],1)])],1)])],1),_c('q-card-actions',{staticClass:"bg-grey-2"},[_c('div',{staticClass:"copyright full-width text-center"},[_vm._v("\n          Powered by\n          "),_c('q-img',{attrs:{"src":"images/ftx-identity.svg","width":"80px","height":"35px"}})],1)])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }