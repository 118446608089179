<template>
  <ftx-dialog
    :dialog="dialog"
    position="standard"
    :maximized="isMobile ? true : false"
    :persistent="true"
    :onHide="onReset"
    :beforeShow="beforeShow"
  >
    <template v-slot:DialogContent>
      <q-card class="simpleDialog q-pa-none">
        <q-card-section
          :class="isMobile ? 'q-px-none q-pt-lg q-pb-md' : 'q-pa-xl'"
        >
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            class="absolute-top-right q-mt-md q-mr-md"
          />

          <div class="container">
            <div class="row q-col-gutter-md justify-center">
              <div class="col-12">
                <div class="ageField">
                  <div class="box"></div>
                  <div class="box ageBox">21<span>+</span></div>
                  <div class="box"></div>
                </div>
              </div>
              <!-- <div class="col-12">
                <div class="text-h5 text-weight-bold text-center">
                  Age Verification
                </div>
              </div> -->
              <q-form
                @submit="onSubmit"
                ref="personalInfoForm"
                @reset="onReset"
              >
                <div class="col-12 text-center">
                  <div class="text-body1">
                    Please verify below details as per your driver's license and
                    continue with Age Verification process
                  </div>

                  <q-scroll-area
                    class="ageVerificationHeight"
                    :thumb-style="scrollAreaThumbStyle"
                    :bar-style="scrollAreaBarStyle"
                    :visible="scrollAreaVisible"
                    style="max-width: 100%"
                  >
                    <div class="row q-col-gutter-md q-mt-md">
                      <div class="col-6">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="First Name*"
                            v-model="personalInfo.firstName"
                            :rules="[
                              (val) => validateRequired(val),
                              validatePersonName(
                                personalInfo.firstName,
                                'Please enter a valid name'
                              ),
                            ]"
                            :lazy-rules="lazyValidationRules"
                            maxlength="40"
                            aria-label="ftxcommerce"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="Last Name*"
                            v-model="personalInfo.lastName"
                            :rules="[
                              (val) => validateRequired(val),
                              validatePersonName(
                                personalInfo.lastName,
                                'Please enter a valid name'
                              ),
                            ]"
                            :lazy-rules="lazyValidationRules"
                            maxlength="40"
                            aria-label="ftxcommerce"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            v-model="personalInfo.dob"
                            mask="##/##/####"
                            label="Date of Birth*"
                            placeholder="MM/DD/YYYY"
                            :rules="[
                              (val) => validateRequired(val),
                              validateFullDOB,
                            ]"
                            :lazy-rules="lazyValidationRules"
                            aria-label="ftxcommerce"
                            @input="onChangeDOB"
                          >
                            <template v-slot:append>
                              <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy
                                  ref="qDateProxy"
                                  transition-show="scale"
                                  transition-hide="scale"
                                >
                                  <q-date
                                    v-model="configureDOB"
                                    mask="MM/DD/YYYY"
                                    :options="isDateAllowed"
                                  >
                                    <div
                                      class="row items-center justify-end q-gutter-sm"
                                    >
                                      <q-btn
                                        label="Cancel"
                                        color="primary"
                                        flat
                                        v-close-popup
                                        @click="cancelDateSelection"
                                      />
                                      <q-btn
                                        label="OK"
                                        color="primary"
                                        flat
                                        v-close-popup
                                        @click="selectDate"
                                      />
                                    </div>
                                  </q-date>
                                </q-popup-proxy>
                              </q-icon>
                            </template>
                          </q-input>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-field pb-0">
                          <GoogleAutoComplete
                            :outlined="true"
                            label="Address Line 1*"
                            :addressFieldChange="addressFieldChange"
                            modelField="addressLine1"
                            :value="personalInfo.addressLine1"
                            :placechanged="getAddressData"
                            :rules="[(val) => validateRequired(val)]"
                            :lazy-rules="lazyValidationRules"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="Address Line 2"
                            v-model="personalInfo.addressLine2"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="City*"
                            v-model="personalInfo.city"
                            :rules="[
                              (val) => validateRequired(val),
                              validatePersonName(
                                personalInfo.city,
                                'Please enter valid city'
                              ),
                            ]"
                            :lazy-rules="lazyValidationRules"
                            aria-label="ftxcommerce"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="State*"
                            v-model="personalInfo.state"
                            :rules="[
                              (val) => validateRequired(val),
                              validatePersonName(
                                personalInfo.state,
                                'Please enter valid state'
                              ),
                            ]"
                            :lazy-rules="lazyValidationRules"
                            aria-label="ftxcommerce"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="Zip Code*"
                            v-model="personalInfo.zip"
                            v-mask="'#####'"
                            :rules="[validateZip]"
                            :lazy-rules="lazyValidationRules"
                            aria-label="ftxcommerce"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="Phone Number*"
                            v-model="personalInfo.phone"
                            v-mask="phoneMask"
                            :rules="[
                              (val) => validateRequired(val),
                              validatePhone(),
                            ]"
                            :lazy-rules="lazyValidationRules"
                            aria-label="ftxcommerce"
                            :disable="isFieldDisabled('phone')"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-field pb-0">
                          <q-input
                            outlined
                            label="Email Address"
                            v-model.trim="personalInfo.email"
                            :rules="[validateEmail]"
                            :lazy-rules="lazyValidationRules"
                            aria-label="ftxcommerce"
                            :disable="isFieldDisabled('email')"
                          />
                        </div>
                      </div>
                    </div>
                  </q-scroll-area>

                  <div class="q-px-md">
                    <div class="row q-col-gutter-md q-mt-xs">
                      <div class="col-6" v-show="isLoggedIn">
                        <div class="form-action pb-0">
                          <q-btn
                            unelevated
                            no-caps
                            color="tertiary"
                            label="Update"
                            class="q-btn-lg full-width"
                            v-close-popup
                            @click="navigateToProfilePage"
                          />
                        </div>
                      </div>
                      <div :class="isLoggedIn ? 'col-6' : 'col-12'">
                        <div class="form-action pb-0">
                          <q-btn
                            unelevated
                            no-caps
                            color="secondary"
                            label="Continue"
                            class="q-btn-lg full-width"
                            type="submit"
                            :loading="loading"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </q-form>
            </div>
          </div>
        </q-card-section>
        <q-card-actions class="bg-grey-2">
          <div class="copyright full-width text-center">
            Powered by
            <q-img src="images/ftx-identity.svg" width="80px" height="35px" />
          </div>
        </q-card-actions>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { hideShowFullSpinner } from 'src/utils'
import IdentityMixin from 'src/utils/IdentityMixin'

let addressTypeMaper = {
  street_number: {
    get: 'short_name',
    set: 'addressLine1',
  },
  route: {
    get: 'long_name',
    set: 'addressLine1',
  },
  locality: {
    get: 'long_name',
    set: 'city',
  },
  administrative_area_level_1: {
    get: 'short_name',
    set: 'state',
  },
  sublocality_level_1: {
    get: 'long_name',
    set: 'addressLine2',
  },
  sublocality_level_2: {
    get: 'long_name',
    set: 'addressLine2',
  },
  sublocality_level_3: {
    get: 'long_name',
    set: 'addressLine2',
  },
  postal_code: { get: 'short_name', set: 'zip' },
}

function initialState() {
  return {
    dialog: 'PersonalInfoDialog',
    loading: false,
    personalInfo: {
      firstName: '',
      lastName: '',
      dob: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: '',
      countryCode: '+1',
    },
    birthDate: '',
  }
}
export default {
  name: 'PersonalInfoDialog',
  mixins: [IdentityMixin],
  data: function () {
    return initialState()
  },
  components: {
    GoogleAutoComplete: () => import('components/common/GoogleAutoComplete'),
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    configureDOB: {
      get() {
        return this.birthDate
      },
      set(val) {
        this.birthDate = val
      },
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
    myProfileUrl() {
      return process.env.MY_PROFILE_URL
    },
  },
  mounted() {
    this.setHeaderHeight()
  },
  methods: {
    getFormattedDate(val) {
      if (!val) return val
      let date = val.replace(
        /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/,
        '$3-$1-$2'
      )
      return date
    },
    isValidDate(val) {
      return /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.test(val)
    },
    onChangeDOB(val) {
      let isValid = this.isValidDate(val)
      if (isValid) this.configureDOB = this.personalInfo.dob
    },
    validateRequiredIf(val, field) {
      if (field && this.personalInfo[field]) {
        return true
      } else {
        return !!val || 'Field is required'
      }
    },
    isDateAllowed(date) {
      return new Date().toISOString().slice(0, 10).split('-').join('/') >= date
    },
    async onSubmit() {
      let dob = this.getFormattedDate(this.personalInfo.dob)
      let reqData = {
        personalInfo: { ...this.personalInfo, dob },
        redirectUri: this.getRedirectUri,
        verificationDuring: this.properties?.verificationDuring || 0,
        customerID: this.properties?.customerId || null,
      }
      if (reqData.personalInfo.phone)
        reqData.personalInfo.phone = this.unmask(
          reqData.personalInfo.phone,
          this.phoneMask
        )

      this.loading = true
      hideShowFullSpinner(true)

      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!this.isCordova && 'FTXIdentityLite' in window) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              if (this.properties?.onSuccessCallback)
                payloadObj.onSuccess = this.properties.onSuccessCallback
              if (this.properties?.onErrorCallback)
                payloadObj.onError = this.properties.onErrorCallback

              this.changeDialogState({
                dialog: 'PersonalInfoDialog',
                val: false,
              })
              this.initEAIVPopup(payloadObj)
            }
            hideShowFullSpinner(false)
          } else if (!response.success && response.message) {
            this.showError(response.message)
            hideShowFullSpinner(false)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onReset() {
      Object.assign(this.$data, initialState())
      if (
        this.$refs.personalInfoForm &&
        this.$refs.personalInfoForm.resetValidation
      )
        this.$refs.personalInfoForm.resetValidation()
    },
    addressFieldChange(field, value) {
      if (this.personalInfo.hasOwnProperty(field)) {
        this.personalInfo[field] = value
      }
    },
    async getAddressData({ addressData, geoLocation, place_id }) {
      Object.keys(addressTypeMaper).forEach((key) => {
        this.addressFieldChange(addressTypeMaper[key].set, '')
      })

      // this.addressFieldChange('line2', '')

      addressData.forEach((addressComponents) => {
        const addressType = addressComponents.types[0]
        if (addressTypeMaper[addressType]) {
          const val = addressComponents[addressTypeMaper[addressType]['get']]

          this.addressFieldChange(
            addressTypeMaper[addressType]['set'],
            this.personalInfo[addressTypeMaper[addressType]['set']]
              ? `${
                  this.personalInfo[addressTypeMaper[addressType]['set']]
                } ${val}`
              : val
          )
        }
      })

      if (geoLocation && geoLocation.latitude && geoLocation.longitude) {
        const geoLocationDetails = `${geoLocation.latitude},${geoLocation.longitude}`

        this.addressFieldChange('geoLocation', geoLocationDetails)
      }
    },
    openURL(url) {
      if (this.isCordova) {
        this.changeDialogState({
          dialog: 'PersonalInfoDialog',
          val: false,
        })
        window.open(url, '_system')
      } else {
        window.location = url
      }
      hideShowFullSpinner(false)
    },
    beforeShow() {
      if (this.properties.personalInfo) {
        this.personalInfo = { ...this.properties.personalInfo }
        if (this.personalInfo?.dob) {
          let date = this.personalInfo.dob.replace(
            /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/,
            '$1/$2/$3'
          )
          this.configureDOB = date
        }
      }
    },
    navigateToProfilePage() {
      const _this = this,
        options = this.isCordova
          ? 'location=no,clearsessioncache=no,clearcache=no,cleardata=no'
          : ''
      const popupWindow = window.open(this.myProfileUrl, '_blank', options)
      if (this.isCordova)
        popupWindow.addEventListener('exit', function (event) {
          _this.$store.dispatch('auth/getUserInfo')
        })
    },
    selectDate() {
      this.personalInfo.dob = this.configureDOB
    },
    cancelDateSelection() {
      this.configureDOB = this.personalInfo.dob
    },
    isFieldDisabled(fieldName) {
      const personalInfo = this.properties.personalInfo
      let isDisable = false
      if (fieldName == 'email') {
        isDisable = (this.isLoggedIn && personalInfo?.email?.length > 0) == true
      } else if (fieldName == 'phone') {
        isDisable = (this.isLoggedIn && personalInfo?.phone?.length > 0) == true
      }
      return isDisable
    },
  },
}
</script>

<style lang="scss" scoped>
.ageVerificationHeight {
  padding: 0 16px;
  height: 465px;
  max-height: calc(100vh - 385px);
  @media (min-width: 1024px) {
    max-height: calc(100vh - 385px);
  }
}
</style>
